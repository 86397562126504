import isNumeric from "validator/lib/isNumeric";

export function sortArrayObj(arr, field, order) {
    return arr.sort((a, b) => {
        switch (field) {
            // numbers
            case "items":
            case "itemsReceived":
            case "itemsAccepted":
            case "itemsRejected":
            case "supplierQuotations":
            case "purchaseOrders":
            case "salesQuotations":
            case "salesOrders":
            case "campaigns":
            case "debits":
            case "credits":
                if (order === 1) {
                    return a[field] - b[field];
                } else {
                    return b[field] - a[field];
                }
            // string to floating point number
            case "creditLimit":
            case "totalAmount":
            case "sum":
                const numberA = parseFloat(a[field]);
                const numberB = parseFloat(b[field]);
                if (order === 1) {
                    return numberA - numberB;
                } else {
                    return numberB - numberA;
                }
            // strings
            default:
                const stringA = a[field].toLowerCase();
                const stringB = b[field].toLowerCase();
                if (stringA < stringB) {
                    return -order;
                } else if (stringA > stringB) {
                    return order;
                } else {
                    return 0;
                }
        }
    });
}

export function dateTimeDifferent(string1, string2) {
    if (string1 && string2) {
        if (string1.includes("T") && string2.includes("T")) {
            return new Date(string1).toISOString() !== new Date(string2).toISOString()
        }
        return true;
    }
    return !!string1 || !!string2;
}

export function contactsDifferent(arr1, arr2) {
    if (arr1.length === arr2.length) return arr1.some(contact =>
        // if arr2 contains an exact match, return false to keep checking the next contact
        !arr2.some(({ name, phone, email }) =>
            contact.name === name && contact.phone === phone && contact.email === email
        )
    )
    return true;
}

export function itemsDifferent(arr1, arr2) {
    if (arr1.length === arr2.length) return arr1.some(item =>
        // if arr2 contains an exact match, return false to keep checking the next item
        !arr2.some(({ name, quantity, unitPrice }) =>
            item.name === name && item.quantity === quantity && item.unitPrice === unitPrice
        )
    )
    return true;
}

export function calculateCharges(obj) {
    const keys = ["serviceFee", "shippingFee", "tax", "otherCharges"];
    return obj.items.reduce((acc, { quantity, unitPrice }) => {
        if (isNumeric(quantity) && parseFloat(quantity) > 0 &&
            isNumeric(unitPrice) && parseFloat(unitPrice) > 0) {
            return acc + parseFloat(quantity) * parseFloat(unitPrice);
        }
        return acc;
    }, 0) + keys.reduce((acc, key) => {
        if (isNumeric(obj[key]) && parseFloat(obj[key]) > 0) {
            return acc + parseFloat(obj[key]);
        }
        return acc;
    }, 0);
}

export function discountInvalid(totalCharges, discount) {
    return !(isNumeric(discount) && parseFloat(discount) >= 0 && parseFloat(discount) <= totalCharges);
}